<template>

  <div class="Preview">
    <signUp  v-if="showAdd" @closeDialog="closeDialog" ref="showAdd"/>
    <h2>
      <!-- 宣汉县小麦种子统一采购报名工作展开公告 -->
      {{formList.title}}</h2>
    <div class="detail">
      <span v-if="procurementId">编号：{{procurementCode}} </span>
      <span v-else> 编号：*******</span>
      <span v-if="procurementId">信息发布主体：{{PublishingMen}}</span>
      <span v-else>信息发布主体：{{PublishingMen}}</span>
      <span  v-if="procurementId">发布时间：{{createTime}}</span>
      <span v-else>发布时间：{{this.LookTime}}</span>

    </div>
    <div class="introduce">
      <span>&#12288&#12288 {{formList.abstracts}}</span>
    </div>
    <div class="table">
      <h4>采购详情</h4>
        <!-- v-if="CollectionVariety[0].tableData.length" -->
        <div v-if="CollectionVariety[0].tableData.length">
               <table border="1"  v-for="(item,index) in CollectionVariety" :key="index">
        <tr>
            <td>采集种类</td>
            <td colspan="2" > {{ item.tableData[0].agriculturalType }} </td>
            <!-- <td>预交费比例</td>
            <td>{{formList.paymentRatio}}%</td> -->
        </tr>
        <tr>
          <td >其他说明</td>
          <td colspan="3">{{formList.others}}</td>
      </tr>
      <tr>
        <td >推荐规格</td>
        <td colspan="3">
          <table-page :data="item.tableData" :columns="columns" :border="true">
            <template slot="operation" slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="goout(scope.row)"
                >删除</el-button
              >
            </template>
          </table-page>
        </td>
    </tr>
      </table>

        </div>

      <table  border="1" v-else v-for="(item,ind) in PurchaseDetails" :key="ind"  >
        <tr>
            <td>采集种类</td>
            <td colspan="2"> {{ item.tableData[0].agriculturalType }} </td>

        </tr>
      <tr>
        <td >推荐规格</td>
        <td colspan="3">
          <table-page :data="item.tableData" :columns="columns" :border="true">
            <template slot="operation" slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="goout(scope.row)"
                >删除</el-button
              >
            </template>
          </table-page>
        </td>
    </tr>
      </table>
  </div>


  <div class="information">
      <table border="1">
        <tr>
          <td>报名截止时间</td>
          <td>{{formList.endTime}}</td>
          <td>预计采购时间</td>
          <td>{{formList.purchasingPeriodTime}}</td>
        </tr>
        <tr>
          <td>集采方式</td>
          <td>线下招投标</td>
          <td>预交费比例</td>
          <td> {{formList.paymentRatio? formList.paymentRatio+"%" :"无"}}</td>
        </tr>
        <tr>
          <td>农资配送方式</td>
          <td>统一配送至各村委会或各村委会指定地点</td>
          <td  >咨询电话</td>
          <td  v-if="procurementId">{{phone}}</td>
          <td  v-else>{{identityObj.userName}}</td>

        </tr>
            <tr>
            <td >其他说明</td>
            <td colspan="3">{{formList.others}}</td>
        </tr>
      </table>
    </div>
  <div class="signUp">
      <el-button type="primary" @click="signUp()"> 报名 </el-button>
    </div>
  <div class="attachments">
      <span class="pdf">附件下载：
    <span class="line" v-for="(item,index) in formList.files" :key="index" @click="Download(item)">
    {{ item.FilesName }}
    </span>
    </span>
      <span v-if="procurementId"> <el-checkbox v-model="form.checked"></el-checkbox>报名参加兴村通统一采购项目，即视为同意 <span class="lineBlue">《兴村通农资集采用户协议》</span></span>
    </div>
    <!-- v-if="identityObj.identityTypeCode=='8'"  -->
  <div class="allBtn" v-if="!procurementId" >
    <el-button @click="$router.back(-1)"> 返回修改 </el-button>
    <el-button type="primary" @click="pay"> 发布 </el-button>
  </div>
  <div class="Tips" >
    <span  v-if="!procurementId">
        *请确认信息输入无误，一经发布，项目信息无法修改
    </span>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  saveProcurement,
  // selectDetailProcurement,
  selectDetailCopy,
} from "@/api/CollectivePurchase";
import TablePage from "@/components/table/CallForBids-page";
import { PreviewList } from "../memberCenter/CollectionMining/tableColumn";
import signUp from "./SignUp";
import { download } from "@/api/ChargeUp";
export default {
  name: "NowPreview",
  components: {
    TablePage,
    signUp,
  },
  data() {
    return {
      columns: PreviewList,
      tableData: [],
      form: {
        checked: false,
      },
      showAdd: false,
      LookTime: "",
      PublishingMen: "",
      procurementId: "", // 集采页面传过来的secondId
      PurchaseDetails: "",
      dataList: [],
      procurementCode: "", //编号
      phone: "", //咨询手机号
      createTime: "", //创建时间
    };
  },
  computed: {
    ...mapGetters([
      "CollectionVariety",
      "formList",
      "identityObj",
      "access_token",
    ]),
  },
  created() {
    //集采信息公告
    this.procurementId = this.$route.query.data;
    this.SignUpList();
  },
  mounted() {
    this.LookTime = this.getCurrentDate();
    console.log("this.LookTime", this.LookTime);
    this.getPublishingMen();
  },

  methods: {
    ...mapMutations(["SET_TABLElIST", "SET_FORMLIST", "SET_DISPOSITION"]),
    // 本地时间
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      //时
      let hour = new Date().getHours();
      //分
      let minute = new Date().getMinutes();
      //秒
      let second = new Date().getSeconds();
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日" +
        hour +
        "：" +
        minute +
        "：" +
        second
      );
    },
    // 集采公告详情
    async SignUpList() {
      // if(this.identityObj)
      // console.log(this.identityObj, "查看这些东西");
      if (this.procurementId) {
        let params = this.procurementId;
        let res = await selectDetailCopy(params);
        console.log(res, "查看res");
        let obj = res.data;
        this.dataList = res.data;
        this.procurementCode = obj.procurementCode;
        this.PublishingMen = obj.operatorName;
        this.phone = obj.phone;
        this.createTime = obj.createTime;
        this.formList.title = obj.title;
        this.formList.purchasingPeriodTime = obj.purchasingPeriodTime;
        this.formList.abstracts = obj.abstracts;
        this.formList.others = obj.others;
        this.formList.createTime = obj.createTime;
        this.formList.endTime = obj.endTime;
        this.formList.paymentRatio = obj.paymentRatio;
        this.formList.files = JSON.parse(obj.files) || [];
        let obj1 = {};
        let newArr = [];
        //转换数据类型
        this.$nextTick(() => {
          obj.newAgriculturalVoList.forEach((item, index) => {
            if (!obj1[item.agriculturalType]) {
              var arr = [];
              arr.push(item);
              newArr.push(arr);
              obj1[item.agriculturalType] = item;
            } else {
              newArr.forEach((value, index) => {
                //如果已经存在  就循环新组的值将值插入属性相同的数组里   为了防止重复添加   只要和第一个比较就可以了
                if (value[0].agriculturalType == item.agriculturalType) {
                  value.push(item);
                }
              });
            }
          });
          let List = [];
          // console.log(newArr, "查看newArr");
          newArr.forEach((item, index) => {
            List.push({ tableData: item });
          });
          // console.log(List, "查看ABC");
          this.PurchaseDetails = List;
        });
      }
    },
    // 信息发布主体
    getPublishingMen() {
      this.PublishingMen = this.identityObj.identityName;
    },

    // 关闭弹框
    closeDialog() {
      this.showAdd = false;
    },
    //点击报名
    signUp() {
      //判断是否登录
      if (this.access_token) {
        // 已登录判断登陆的用户是否有权限
        if (
          this.identityObj.identityTypeCode == "8" ||
          this.identityObj.identityTypeCode == "2"
        ) {
          this.$message.info(
            "农资集采活动仅支持集体经济组织和新型经营主体报名参加"
          );
        } else {
          //判断报名项目是否到期
          if (this.dataList.status == 2) {
            this.$message.info("此活动报名已截止，请选择其他活动");
          } else {
            let obj = this.dataList;
            if (this.form.checked) {
              this.showAdd = true;
              this.$nextTick(() => {
                this.$refs.showAdd.initSignUp(obj);
              });
            } else {
              this.$message.info("请先阅读新村通协议，勾选即代表同意本次协议");
            }
          }
        }
      } else {
        // 未登录
        this.$confirm("您未登录，不可查看联系方式！是否登录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    //发布
    async pay() {
      //判断是否是运营公司
      if (this.identityObj.identityTypeCode == "8") {
        let procurementList = [];
        this.CollectionVariety.forEach((item) => {
          procurementList.push(...item.tableData);
        });
        let newProcurementList = [];
        procurementList.forEach((item) => {
          newProcurementList.push({
            agriculturalId: item.agriculturalId,
            maxPrice: item.guidePrice,
          });
        });
        let params = {
          ...this.form,
          ...this.formList,
          procurementList: procurementList,
        };
        params.status = 1;
        params.files = JSON.stringify(this.formList.files);
        params.areaCode = JSON.parse(
          localStorage.getItem("userInfo")
        ).content.identityList[0].areaCode;
        let res = await saveProcurement(params);
        if (res.code == 0) {
          let tableList = [{ agriculturalType: "", tableData: [] }];
          this.SET_TABLElIST(tableList);
          let form = {
            title: "", //标题
            abstracts: "", //摘要
            others: "", //其他说明
            // createTime:'',//开始时间，
            endTime: "", //结束时间，
            purchasingPeriodTime: "", //预计采购时间
            status: "", //状态
            files: [], //文件
            paymentRatio: "", //支付比例
            areaCode: "", //地址
          };
          this.SET_FORMLIST(form);
          // this.SET_DISPOSITION([]);
          // this.formList(form);
          this.$message.success(res.msg);
          this.$router.push("/memberCenter/CollectionMining/AddCollection");
        } else {
          this.$message.error(res.msg);
        }
      } else {
        // 用户打算报名
        if (this.form.checked) {
          let procurementList = [];
          this.CollectionVariety.forEach((item) => {
            procurementList.push(...item.tableData);
          });
          let newProcurementList = [];
          procurementList.forEach((item) => {
            newProcurementList.push({
              agriculturalId: item.agriculturalId,
              maxPrice: item.guidePrice,
            });
          });

          let params = {
            ...this.form,
            ...this.formList,
            procurementList: procurementList,
          };

          params.status = 1;
          params.files = JSON.stringify(this.formList.files);
          params.areaCode = JSON.parse(
            localStorage.getItem("userInfo")
          ).content.identityList[0].areaCode;
          let res = await saveProcurement(params);
          if (res.code == 0) {
            let tableList = [{ agriculturalType: "", tableData: [] }];
            this.SET_TABLElIST(tableList);
            let form = {
              title: "", //标题
              abstracts: "", //摘要
              others: "", //其他说明
              // createTime:'',//开始时间，
              endTime: "", //结束时间，
              purchasingPeriodTime: "", //预计采购时间
              status: "", //状态
              files: [], //文件
              paymentRatio: "", //支付比例
              areaCode: "", //地址
            };
            this.SET_FORMLIST(form);
            // this.SET_DISPOSITION([]);
            // this.formList(form);
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.info("请先查看协议 ");
        }
      }
    },
    //下载
    async Download(file) {
      let itemList = file;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;

        let ToName = itemList.FilesName;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Preview {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  h2 {
    text-align: center;
  }
  .detail {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 18px;
    }
  }
  .introduce {
    height: 60px;
    width: 97%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .table {
    width: 100%;
    margin-top: 5px;
    border-top: 2px solid #1ea84d;
    h4 {
      margin: 5px 10px;
    }
    table {
      width: 97%;
      margin: 0 auto;
      margin-top: 20px;
      border-collapse: collapse;
      text-align: center;

      tr {
        td {
          padding: 5px 5px;
        }
      }
    }
  }
  .information {
    table {
      width: 97%;
      margin: 0 auto;
      margin-top: 20px;
      border-collapse: collapse;
      text-align: center;
      tr {
        td {
          padding: 5px 5px;
        }
      }
    }
  }
  .signUp {
    width: 95%;
    height: 50px;
    margin-top: 20px;
    button {
      float: right;
    }
  }
  .attachments {
    width: 97%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    span {
      margin-top: 10px;
    }
    .pdf {
      padding-left: 25px;
    }
    .el-checkbox {
      margin-right: 10px;
    }
    .line {
      text-decoration: underline;
      margin-left: 10px;
    }
    .lineBlue {
      color: #0000c5;
    }
  }
  .allBtn {
    padding-top: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
  }
  .Tips {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    span {
      color: #ec809c;
      font-size: 10px;
    }
  }
}
</style>
